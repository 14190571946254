<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import simplebar from "simplebar-vue";
import ApiClass from "../../api/api";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";

/**
 * Lending component
 */
export default {
  page: {
    title: "Vendor List",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, simplebar, Multiselect },
  data() {
    return {
      title: "Vendor List",
      items: [
        {
          text: "Vendor List",
          href: "#",
        },
        {
          text: "Vendor",
          active: true,
        },
      ],
      loading: false,
      totalRows: 0,
      currentRowData: "",
      currencylist: [],
      form: {
        token: "",
      },
      currentPage: 1,
      perPage: 5,
      sortBy: "value",
      largeImg: "",
      sortDesc: false,
      fields: [
        {
          key: "id",
          value: "#",
          sortable: false,
        },
        {
          key: "name",
          value: "name",
          sortable: false,
        },
        {
          key: "email",
          value: "email",
          sortable: false,
        },
        {
          key: "status",
          value: "status",
          sortable: false,
        },
        {
          key: "status_detail",
          value: "status_detail",
          sortable: false,
        },
        {
          key: "verification",
          value: "verification",
          sortable: false,
        },
        {
          key: "is_admin_verify",
          value: "is_admin_verify",
          sortable: false,
        },
        {
          key: "assigned_tokens",
          value: "assigned_tokens",
          sortable: false,
        },
        {
          key: "created_at",
          value: "created_at",
          sortable: false,
        },
        {
          key: "action",
          value: "action",
          sortable: false,
        },
      ],
    };
  },
  mounted() {
    this.currencyStore();
  },
  methods: {
    async currencyStore() {
      var result = await ApiClass.getRequest(
        "admin/list-coins/token_list",
        true
      );
      this.currencylist = result.data.data ?? [];
    },
    async check(e, colm) {
      // var status = e.active_status == true ? 1 :0;
      await ApiClass.updateRequest(
        "admin/vendor/update_status/" + e.id + "/" + e[colm] + "/" + colm,
        true
      );
    },
    async onSubmit(event, id) {
      event.preventDefault();
      var result = await ApiClass.postRequest(
        "admin/vendor/assign_token/" + id,
        true,
        this.form
      );
      if (result.data.status_code == "1") {
        Swal.fire({
          position: "center",
          icon: "success",
          title: result.data.message,
          showConfirmButton: false,
          timer: 5000,
        });
        this.$refs.table.refresh();
        this.$root.$emit("bv::hide::modal", "modal-center");
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: result.data.message,
          showConfirmButton: false,
          timer: 5000,
        });
      }
    },

    async adminVerify(e) {
      var status = e.is_admin_verify ? 1 : 0;
      var result = await ApiClass.updateFormRequest(
        "admin/vendor/verify/" + e.id,
        true,
        { status: status }
      );
      if (result.data.status_code == "1") {
        Swal.fire({
          position: "center",
          icon: "success",
          title: result.data.message,
          showConfirmButton: false,
          timer: 5000,
        });
      } else {
        e.is_admin_verify = !e.is_admin_verify;
        Swal.fire({
          position: "center",
          icon: "error",
          title: result.data.message,
          showConfirmButton: false,
          timer: 5000,
        });
      }
    },
    async myProvider(ctx, callback) {
      this.loading = !this.loading;
      await ApiClass.getRequest(
        "admin/vendor/get?per_page=" + ctx.perPage + "&page=" + ctx.currentPage,
        true
      )
        .then((res) => {
          if (res.data.status_code == 1) {
            this.loading = !this.loading;
            const items = res.data.data ? res.data.data.data : [];
            this.currentPage = res.data.data ? res.data.data.current_page : "";
            this.totalRows = res.data.data ? res.data.data.total : "";

            callback(items);
          }
        })
        .bind(this);
    },
    onclose() {
      this.$root.$emit("bv::hide::modal", "modal-center");
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Vendor List</h4>
            <simplebar style="max-height: 600px">
              <div class="table-responsive">
                <b-table
                  :items="myProvider"
                  :fields="fields"
                  responsive="sm"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :busy="loading"
                  ref="table"
                  show-empty
                >
                  <template #empty>
                    <div class="d-flex justify-content-center mb-3">
                      No Record Found
                    </div>
                  </template>

                  <template #table-busy>
                    <div class="text-center text-info my-2">
                      <b-spinner class="align-middle"></b-spinner>
                      <!-- <strong>Loading...</strong> -->
                    </div>
                  </template>

                  <template v-slot:cell(id)="data">
                    <div v-if="data.item.id">
                      {{ data.item.id }}
                    </div>
                  </template>

                  <template v-slot:cell(name)="data">
                    <div v-if="data.item.name">
                      {{ data.item.name }}
                    </div>
                  </template>

                  <template v-slot:cell(email)="data">
                    <div v-if="data.item.email">
                      {{ data.item.email }}
                    </div>
                  </template>

                  <!-- Status section -->
                  <template v-slot:cell(status)="data">
                    <div class="form-check form-switch form-switch-md mb-3">
                      <input
                        v-model="data.item.status"
                        @change="check(data.item, 'status')"
                        class="form-check-input"
                        type="checkbox"
                        id="SwitchCheckSizemd"
                      />
                    </div>
                  </template>

                  <template v-slot:cell(status_detail)="data">
                    <div v-if="data.item.status == 1">
                      <span class="text-success"><strong>Active</strong></span>
                    </div>
                    <div v-else>
                      <span class="text-danger"
                        ><strong>In Active</strong></span
                      >
                    </div>
                  </template>

                  <template v-slot:cell(verification)="data">
                    <div v-if="data.item.is_admin_verify == 1">
                      <span class="text-success"
                        ><strong>Approved</strong></span
                      >
                    </div>
                    <div v-else>
                      <span class="text-danger"><strong>Pending</strong></span>
                    </div>
                  </template>

                  <!-- Status section -->
                  <template v-slot:cell(is_admin_verify)="data">
                    <div class="form-check form-switch form-switch-md mb-3">
                      <input
                        v-model="data.item.is_admin_verify"
                        @change="adminVerify(data.item)"
                        class="form-check-input"
                        type="checkbox"
                        id="SwitchCheckSizemd"
                      />
                    </div>
                  </template>

                  <template v-slot:cell(assigned_tokens)="data">
                    <div v-if="data.item.assigned_tokens">
                      {{ data.item.assigned_tokens }}
                    </div>
                  </template>

                  <template v-slot:cell(created_at)="data">
                    <div v-if="data.item.created_at">
                      {{ new Date(data.item.created_at).toLocaleDateString() }}
                    </div>
                  </template>

                  <template v-slot:cell(action)="data">
                    <b-button
                      v-b-modal.modal-center
                      variant="white"
                      @click="currentRowData = data.item"
                      class="btn btn-outline-info"
                      >Assign Token</b-button
                    >
                  </template>

                  action
                </b-table>
              </div>
              <div class="row m-0">
                <div class="col p-0">
                  <div
                    class="dataTables_paginate paging_simple_numbers float-end"
                  >
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                      ></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </simplebar>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal for assigning token to vendor -->
    <b-modal
      id="modal-center"
      size="xl"
      centered
      title="Assign Token"
      title-class="font-18"
      hide-footer
      v-if="currentRowData"
      class="padding_modal"
    >
      <b-form @submit="onSubmit($event, currentRowData.id)">
        <div class="mb-3">
          <label for="exampleFormControlInput1" class="form-label"
            >Vendor Email</label
          >
          <input
            type="email"
            class="form-control"
            id="exampleFormControlInput1"
            :value="currentRowData.email"
            placeholder="name@example.com"
            readonly
          />
        </div>
        <div class="mb-3">
          <label for="exampleFormControlInput1" class="form-label"
            >Vendor Name</label
          >
          <input
            type="email"
            class="form-control"
            id="exampleFormControlInput1"
            :value="currentRowData.name"
            placeholder="name@example.com"
            readonly
          />
        </div>
        <div class="mb-3">
          <label for="exampleFormControlTextarea1" class="form-label"
            >Select Token</label
          >
          <multiselect
            v-model="form.token"
            :options="currencylist"
            required
          ></multiselect>
        </div>
        <div class="mt-5 text-end">
          <button type="submit" class="btn btn-outline-info">
            Assign Token
          </button>
        </div>
      </b-form>
    </b-modal>

    <!-- end row -->
  </Layout>
</template>


<style scoped>
.front_image img,
.back_image img {
  width: 150px;
  height: 120px;
}
.succ_btn {
  float: right;
  clear: both;
  margin: 20px 0px;
}
.clr-red {
  color: red;
}
.success {
  color: green;
  font-weight: 600;
  font-size: 14px;
}

div#modal-center___BV_modal_body_ {
  padding: 40px 20px !important;
}
</style>